@labelCol: @black;

@inputCol: @black;
@backgroundCol: transparent;

@formFontSize: 23px;

@submitCol: #f04152;
@submitBg: @black;

@borderCol: @inputCol;

.border {
  margin-top: 10px;
}
.form-holder {
  width: 350px;
  float: left;
}
.error {
  color: red !important;
}
.form-title {
  width: 100%;
  color: @labelCol;
  height: 33px;
  padding: 7px 4px 0px 6px;
  margin-bottom: 2px;
}
.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  border-radius: 0px;
  appearance: none;
  height: 50px;
  width: 100%;
  border: 1px;
  font-size: @formFontSize;
  .sans;
  font-weight: 300;
  letter-spacing: 2px;
  background-color: @backgroundCol;
  color: @inputCol;
  outline: none;
}

.custom-textarea {
  height: 100px;
}

.custom-select {
  padding-top: 4px;
  height: 50px;
  box-sizing: border-box;
  background: url("../img/core/arrow.png") 97% 50% no-repeat @backgroundCol;
}

.custom-submit {
  color: @grey;
  box-shadow: none;
  margin: @top-spacing auto 0 auto;
  font-size: @formFontSize;
  text-align: center;
  transition: 0.5s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 50%;
  background-color: @black;
  transform: translateX(50%);
  &:hover {
      //  background-color: #28baaf;
  }
}


.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container h4 {
  color: @black;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
label {
  color: @labelCol;
  font-weight: bold;
}
::placeholder {
  color: #767676;
}
option {
  color: #000000 !important;
}

.customSelectInner {
    width: 100% !important;
}

.form-field-left-half, .form-field-right-half, .form-field-full {
  float: left;
  width: 50%;
  height: 50px;
}
.form-field-right-half {
  float: right;

}
.form-field-full {
  width: 100%;
}
.form-field{
  clear: both;
  border-bottom: 1px solid @black;
}

// md styles
@media (max-width: 1199px) {
  
}

// sm styles
@media (max-width: 991px) {
  .text-input,
  .custom-select,
  .custom-submit,
  .custom-textarea{
      font-size: 17px;
  }
}

// xs styles
@media (max-width: 767px) {
  .text-input,
  .custom-select,
  .custom-submit,
  .custom-textarea{
      font-size: 16px;
  }

  .custom-submit {
    height: 30px;
  }
}
