@import (less) "normalize.css";
@import (less) "../node_modules/bootstrap/dist/css/bootstrap.css";

@import (less) "../recaptcha/recaptcha.css";

@import (less) "../js/vendor/royalslider/royalslider.css";
@import (less) "../js/vendor/royalslider/skins/default/rs-default.css";
@import (less) "hamburgers.less";

@import (less) "savi-core.less";
@import (less) "fonts.less";

@import (less) "variables.less";
@import (less) "menu-horizontal.less";
@import (less) "slider.less";
@import (less) "dropkick.less";
@import (less) "form.less";
@import (less) "thankyou.less";
@import (less) "register.less";
@import (less) "home.less";
@import (less) "vr-iframe.less";
@import (less) "google-map.less";
@import (less) "footer.less";
@import (less) "animate.less";

body, html {
    background-color: @grey;
}

.failures {
    .menu-wrapper {
        display: none;
    }
}

//IE Target for menu height
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .menu-wrapper {
        height: 150px;
    }
}

.logo {
    width: 300px;
    // margin: 0 auto;
    margin: 20px auto;
}

.row {
    margin-right: 0;
    margin-left: 0;
}

.overflow {
    overflow: hidden;
}

.heading, .copy {
    text-align: center;
    margin: 0 auto;
    h1,h3 {
        color: @black;
        .serif;
    }
    h1 {
        font-size: @font-xlg;
        margin-top: 60px;
        margin-bottom: 30px;
    }
    h3 {
        font-size: 35px;
        text-align: center;
    }

    p:first-of-type {
        margin-top: @top-spacing;
    }
    p{
        font-size: @font-md;
        color: @black;
        .sans;
    }
}

.heading {
    width: 60%;
}

.copy {
    width: 45%;
}

.copy-lg {
    width: 100%;
    p {
        font-size: 32px;
        line-height: normal;
        line-height: 35px;
    }
}

.hero-heading {
    h1, h3 {
        color: @white;
    }
    h3 {
        margin-top: 20px;
    }
}

.hero-copy {
    p {
        color: @white;
    }
}

.architecture,  .walkthrough {
    margin-top: @top-spacing;
}

.lifestyle, .walkthrough {
    padding-bottom: @edge-spacing;
}

.register {
    .sans;
    text-transform: uppercase;
    background-color: @white;
    padding: 15px;
    a {
        font-size: @font-md;
        color: @black;
        letter-spacing: 1px;
    }
    &:hover {
        a {
            text-decoration: none;
            color: @white;
        }
        border: 1px solid @white;
        background-color: @black;
    }
}

.caption {
    font-size: 8px;
    position: absolute;
    bottom: 0;
    left: 10px;
    color: @white;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
    .logo {
        width: 300px;
        padding-top: @md-top-spacing;
    }
    // .anplus {
    //     top: @md-top-spacing;
    //     width: 34px;
    // }
    .register {
        font-size: @font-sm;
        top: @md-top-spacing;
    }
    .heading, .copy {
        width: 75%;
        p:first-of-type {
            margin-top: @sm-top-spacing;
        }
        p{
            margin-top: @sm-top-spacing;
        }
    }
    .register {
        letter-spacing: 1px;
    }
}

// xs styles
@media (max-width: 767px) {
    .logo {
        width: 160px;
        margin: 0 auto;
        padding-top: 25px;
    }

    .register {
        top: 15px;
        right: 15px;
        text-align: center;
        letter-spacing: normal;
        padding: 2px;
        width: 75px;
        a {
            font-size: 10px;
            display: block;
            width: 100%;
            text-align: center;
            font-weight: bolder;
            height: 28px;
            line-height: 11px;
            padding-top: 4px;
        }
    }
    .register-mobile-menu {
        top: 30px;
        right: 15px;
        background-color: @black;
        a {
            color: @white;
        }
    }
    .artist-impression {
        p {
            font-size: 9px;
        }
    }
    .heading, .copy {
        margin: 0 auto;
        h1 {
            font-size: 25px;
        }
        h3 {
            font-size: 17px;
        }
        p{
            font-size: @font-sm;
            margin-top: 0;
            line-height: 18px;
        }
    }

    .copy {
        width: 100%;
    }

    .heading {
        width: 80%
    }
    .hero {
        min-height: calc(~"100vh - " 75px);
    }
    .architecture, .lifestyle {
        margin-top: 0;
    }
    .heading{
        h1:last-of-type {
            margin-top: 20px;
        }
    }
    .lifestyle-heading {
        width: 100%;
    }
}

// xl styles
@media (min-width : 1900px) {
    .artist-impression {
        p {
            font-size: 15px;
        }
    }
}