.map-wrapper {
  margin-top: @lg-top-spacing;
  .map {
    background-color: #fff;
    min-height: 50vh;
  }

  #google-map-canvas {
    width: 100%;
    height: 600px;
  }
}

//Override google map styles
.gm-style-iw-d {
  color: @black;
}

.gm-style .gm-style-iw-c {
  border-radius: 0;
}

@media (max-width: 767px) {
  .map-wrapper {
    #google-map-canvas {
      width: 100%;
      height: 350px;
    }
  }
}