// FONTS
@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

// COLOURS
@core-color__default:   #000000;
@black:                 #000000;
@white:                 #ffffff;
@grey:                  #f0f0f0;


@core-dropkick-bg:      #d9d9d2;
@core-dropkick-col:     #000000;



@menu-wrapper-height: 120px;

@edge-spacing:          50px;
@top-spacing:           50px;
@sm-top-spacing:        20px;
@md-top-spacing:        40px;
@lg-top-spacing:        100px;
@top-menu-spacing:      70px;

@font-xlg:              50px;
@font-lg:               19px;
@font-md:               17px;
@font-sm:               15px;

.sans {
    font-family: 'GroteskLight';
}

.serif {
    font-family: 'RecklessLight';
}

.builder {
  color: @white;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color: @grey;
  }
}
