.register-wrapper, .thankyou-wrapper {
    background-color: @white;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: -200vh;
    left: 0;
    z-index: 1000;
    transition: top 1s ease;

    .svg-logo {
        fill: @black;
    }

    .svg-anplus {
        fill: @black;
    }
}

.register-wrapper {
    overflow: scroll;
}

.thankyou-wrapper {
    height: 100vh;
}

.reg-content {
    position: relative;
    height: 100vh;
    z-index: 999999;
    .logo {
        padding-top: 25px;
    }
}

.reg-active, .thankyou-active {
    top: 0;
    left: 0;
}

.close-wrapper {
    position: absolute;
    top: 25px;
    right: @edge-spacing;
    width: 30px;
}

.form-wrapper {
    width: 50vw;
    // position: absolute;
    // top: 50%;
    // left: 0;
    // right: 0;
    // transform: translateY(-50%);
    margin: auto;
}

.register-footer {
    color: @black;
    width: 100%;
    text-align: center;
    padding-top: @edge-spacing;
    padding-bottom: @edge-spacing;
    text-align: center;
    .sans;
    font-size: @font-md;
    a {
        color: @black;
        &:hover {
        text-decoration: none;
        }
    }
}

.register-heading {
    padding-top: @edge-spacing;
    padding-bottom: @edge-spacing;
    h2 {
        text-align: center;
        font-size: 50px;
        .serif;
    }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
    .close-wrapper {
        top: @md-top-spacing;
        width: 25px;
    }
    .form-wrapper {
        width: 75vw;
    }
}

// xs styles
@media (max-width: 767px) {
    .register-heading {
        h2 {
            font-size: 30px;
        }
    }

    .close-wrapper {
        top: @sm-top-spacing;
        width: 20px;
        right: 32px;
    }

    .form-wrapper {
        width: 100vw;
        height: auto;
        padding-left: @edge-spacing;
        padding-right: @edge-spacing;
        padding-top: @sm-top-spacing;
        background-color: @white;
    }

    .reg-content {
        height: 100vh;
    }

    .register-wrapper {
        height: 100vh;
        background-color: @white;
    }
}

