.thankyou {
    .logo {
        padding-top: 30px;
    }
    .hide-tracker {
        height: 0 !important;
        width: 0 !important;
        line-height: 0 !important;
        font-size: 0 !important;
        margin-top: -13px;
        float: left;
    }

    .thankyou-message {
        width: 100%;
        font-size: 19px;
        margin: 0 auto;
        text-align: center;
        .sans;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .spacing {
        margin-top: @top-spacing;
    }

    .email {
        color: @black;
        &:hover {
            text-decoration: none;
            border-bottom: 1px solid @black;
        }
    }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
    .thankyou {
        .thankyou-message {
            width: 100vw;
        }
    }
}

// xs styles
@media (max-width: 767px) {
}