// .hero {
//   //min-height: calc(~"100vh - 220px");
//   position: relative;
//   min-height: calc(~"100vh - 130px");
//   background-image: url(../img/hero/01.jpg);
//   background-size: cover;
//   background-position: right top;
//   //margin-top: 120px;
//   img {
//       width: 100%;
//   }
// }
// .hero-ls {
//   min-height: calc(~"100vh"); // - 120px
//   position: relative;
//   background-color: @black;
// }

// .hero-rs {
//   background-color: @black;
//   position: relative;
//   min-height: calc(~"100vh"); // - 120px
//   background-image: url(../img/hero/01.jpg);
//   background-size: cover;
//   background-position: right center;
//   background-repeat: no-repeat;
// }

.hero {
  background-color: @black;
  position: relative;
  min-height: calc(~"100vh"); // - 120px
  background-image: url(../img/hero/03.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.hero-copy-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.hero-text {
  width: 210px;
  margin: 0 auto;
  padding-top: 25px;
}

.hero-arrow {
  margin: 0 auto;
  width: 25px;
  padding-top: 25px;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
}

.hero-artist {
  position: absolute;
  bottom: 0;
  .sans;
  color: @white;
  right: 15px;
  text-shadow: 1px 1px @black;
}

.hero-heading {
  width: 100%;
  margin-bottom: 20px;
}

.hero-agent {
  text-align: center;
  padding-top: 30px;
  .sans;
  color: @white;
  font-size: @font-lg;
  a {
    color: @white;
    &:hover {
      text-decoration: none;
      color: @white;
    }
  }
}

.messaging-wrapper {
  background-color: red;
  color: #ffffff;
  text-align: center;
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 3;
  padding: 5px 0;
  transition: 1s ease all;
  p {
    margin: 0;
    padding: 0;
    font-size: 1.4em;
    display: inline;
  }
}
.messaging-wrapper__active {
  top: 120px;
}

.close-construction {
  float: right;
  margin-right: 20px;
  color: #ffffff;
  font-size: 1.4em;
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
}

.hero-button {
  background-color: @white;
  padding: 10px 20px;
  color:@black;
  border: 1px solid @white;
  display: block;
  width: 20%;
  margin: 0 auto;
  transition: 1s ease all;
  letter-spacing: 1px;
  text-transform: uppercase;
  &:hover {
    background-color: rgba(255,255,255,0.3);
    text-decoration: none;
    color: @black;
  }
  &:active, &:focus {
    color: @black;
  }
}


@media (max-width: 991px) {
  // .hero {
  //   min-height: calc(~"100vh - 300px");
  // }
}

@media (max-width: 771px)  {
  // .hero {
  //   min-height: calc(~"100vh - 500px");
  //   margin-top: 145px;
  //   margin-left: 15px;
  //   margin-right: 15px;
  // }

  .hero-text {
    width: 210px;
  }

  .hero-artist {
    font-size: 10px;
  }
  .messaging-wrapper__active {
    top: 100px; //Height of top menu
  }
  .hero-button {
    width: 75%;
  }
  .messaging-wrapper {
    padding: 5px 5px;
    p {
      font-size: 1.2em;
    }
  }
  .close-construction {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

