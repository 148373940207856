.footer {
  background-color: @black;
  padding-top: @edge-spacing;
  padding-bottom: 10px;
}

.anplus, .nottinghill, .backtotop {
  background-color: @black;
}

.anplus {
  width: 30px;
  margin-left: @edge-spacing;
}

.nottinghill {
  width: 150px;
  margin: 0 auto;
}

.backtotop {
  width: 65px;
  float: right;
  margin-right: @edge-spacing;
}

.agent {
  text-align: center;
  padding-top: 30px;
  background-color: @black;
  .sans;
  color: @white;
  font-size: @font-md;
  a {
    color: @white;
    &:hover {
      text-decoration: none;
      color: @white;
    }
  }
}

.clear-margin {
  margin: 0;
}

@media (max-width: 991px) {
  .nottinghill {
    width: 122px;
  }
  .backtotop {
    width: 65px;
    right: 15px;
  }
}

@media (max-width: 771px)  {

}