.menu-horizontal {
	list-style: none;
	padding: 0;
	margin: 10px 0;
	text-align: center;
	li {
		display: inline-block;
		list-style: none;
    	padding: 0;
		margin-right: 20px;
		text-transform: uppercase;
		// a {
		// 	transition: 0.5s ease;
		// 	text-decoration: none;
		// 	&:hover, :focus {
		// 		transition: 0.5s ease;
		// 		 text-decoration: none;
		// 		 color: @white;
		// 	}
		// }
		&:last-child {
			margin-right: 0px;
		}
	}
}

.inner-menu {
	position: relative;
}

.menu-wrapper {
	background-color: @black;
  position: fixed;
	padding-top: ~"calc(@{top-spacing} / 2)";
	height: ~"calc(@{top-menu-spacing} + @{top-spacing})";
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	padding-left: 0;
	padding-right: 0;
}

.menu-ls {
  position: absolute;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
}

.menu-rs {
  position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
}

.menu-link {
	.sans;
	text-transform: uppercase;
	font-size: @font-md;
	color: @white;
	letter-spacing: 2px;
	&:hover {
			text-decoration: none;
			border-bottom: 1px solid @white;
			color: @white;
	}
}

.menu-link-active {
	border-bottom: 1px solid @white;
	a {
		text-decoration: none;
		color: @white;
	}
}

//iPad

// .menu-wrapper {
//   height: 180px;
//   padding: 30px;
//   left: 0;
// }

.mobile-menu {
  width: 100%;
  position: fixed;
  top: -400px;
  z-index: 500;
  background-color: @white;
  text-align: center;
  transition: top 1s ease;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding-bottom: @sm-top-spacing;
  }
  a {
    color: @black;
  }
  .svg-logo {
    fill: @black;
  }
  .logo {
    padding-top: @md-top-spacing;
    padding-bottom: @md-top-spacing;
    width: 190px;
  }
}

.menu-link {
  color: @black;
  letter-spacing: 2px;
  &:hover {
      text-decoration: none;
      border-bottom: none;
      color: @black;
  }
}

.menu-link-active {
  border-bottom: 1px solid @white;
  a {
    text-decoration: none;
    color: @white;
  }
}


.menu-rs {
  top: 30px;
  transform: none;
}

.mobile-menu-active {
  top: 0;
}


.inner-menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}



.hamburger {
  position: fixed;
  width: 30px;
  top: 30px;
  left: 15px;
  padding: 0;
  z-index: 100;
}

.close-menu {
  position: absolute;
  width: 25px;
  left: 15px;
  top: 30px;
  z-index: 200;
}

@media (max-width: 991px)  {
  .menu-wrapper {
    height: 180px;
    padding: 30px;
    left: 0;
  }
}

@media (max-width: 771px){
	.menu-wrapper {
		height: 100px;
	}
}

@media (max-width: 480px)  {
	.menu-wrapper {
		width: 100%;
		height: 80px;
	}

	.menu-rs {
		right: 30px;
	}

	.hamburger {
		// left: 30px;
		width: 30px;
		left: 15px;
		top: 15px;
	}
	.close-menu {
		width: 20px;
	}
}


