.vr-iframe {
  width: 100%;
  height: 70vh;
  margin-top: 100px;
}

.vr-iframe__fullscreen {
  width: 100%;
  height: 100vh;
  margin-top: 0;
}

.virtualtour {
  .menu-wrapper, .messaging-wrapper, .hamburger, .mobile-menu {
    display: none;
  }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .vr-iframe {
    height: 60vh;
    margin-top: 40px;
  }
}

// xs styles
@media (max-width: 767px) {

}