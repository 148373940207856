.royalSlider {
    width: 100%;
    .rsBullets {
        position: relative;
        z-index: 35;
        left: 0;
        bottom: 50px;
        width: 100%;
        height: 20px;
        margin: 0 auto;
        background: transparent;
        text-align: center;
        line-height: 8px;
        overflow: hidden;
    }

    .rsBullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        padding: 6px 11px 6px;
        span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
        &.rsNavSelected {
            span {
                background-color: transparent;
            }
        }
    }

    .rsSlide {
        background-color: @black;
        img {
            // opacity: 0.45 !important;
            transition: all 0.3s ease-out;
            transform: scale(1);
        }
    }

    .rsActiveSlide {
        img {
            opacity: 1 !important;
            transform: scale(1);
        }
    }
}

.basic-slider {
    margin-top: @lg-top-spacing;
}

.slider-wrapper {
    position: relative;
    height: 100%;
    margin: 0 auto;
    clear: both;
}
.img-wrapper {
    margin: 0 auto;
}

.rsDefault {
    &.rsHor {
        .rsArrowRight,
        .rsArrowLeft {
            .rsArrowIcn {
                background-color: transparent;
                width: 44px;
                height: 30px;
                background-position: top left;
                background-repeat: no-repeat;
                margin:0;
            }
        }
        .rsArrowLeft {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowLeft.png');
                background-image: none;
                left: 0;
            }
        }
        .rsArrowRight {
            .rsArrowIcn {
                background-image: url('/img/sliders/rsSlider_arrowRight.png');
                background-image: none;
                right: 0;
                left: auto;
            }
        }
    }

    .rsOverflow {
        background: transparent;
    }
    .rsGCaption {
        background-color: transparent;
        left: 12px;
    }
    .rsBullet {
        background: transparent;
    }
}

.disclaimer-text {
    .sans;
    color: @white;
    position: absolute;
    bottom: 10px;
    left: 20px;
}



.rsDefault,
.rsSlide {
    background-color: @black;
}


.sliderContainer {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.rsGCaption {
    .sans;
    margin-bottom: 0;
    color: @black;
}

.slide-container {
    //position: relative;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}
.slider-control {
    position: relative;
    margin-top: @sm-top-spacing;
}
.nav-slider {
    width: 15px;
    height: 43px;
}

#slider-caption-architecture, #slider-caption-lifestyle{
    width: 100%;
    text-align: center;
    .sans;
    color: @black;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: @font-sm;
} 

.special-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
}


.rs-arrow {
    position: absolute;
    top: 0;
    right: 0;
}

.ls-arrow {
    position: absolute;
    top: 0;
    left: 0;
}
.slide-bottom {
    position: absolute;
    bottom: 20px;
    color: red;
    img {
        width: 20px;
    }
}

.rsContent {
    position: relative;
    h2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        .sans;
        font-size: 34px;
        margin: 0;
        left: 0;
        right: 0;
    }
}


.hide-slide{
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease-out;
}

.slider-text__first {
    width: 100%;
    position: absolute;
    bottom: 100px;
    img {
        display: block;
        width: 200px;
        margin: 0 auto;
    }
}

.slider-text {
    img {
        width: 220px;
    }
    text-align: center;
    width: 100%;
}

.basic-slider__slide {
    left: 12px;
}
.basic-slider__slide2 {
    left:270px;
}

// md styles
@media (max-width: 1199px) {
    .basic-slider {
        margin-top: @md-top-spacing;
    }
}

// sm styles
@media (max-width: 991px) {
    #slider-caption-architecture, #slider-caption-lifestyle{
        letter-spacing: 1px;
    } 
}

// xs styles
@media (max-width: 767px) {
    #slider-caption-architecture, #slider-caption-lifestyle{
        font-size: 10px;
        padding-top: 5px;
    }
    .disclaimer-text {
        bottom: 10px;
        left: 10px;
        font-size: 10px;
    }
    .nav-slider {
        width: 10px;
    }
}
// xl styles
@media (min-width : 1900px) {
    
}